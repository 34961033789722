/* GOOGLE FONTS */
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* CUSTOM PROPERTIES */

/*
  The :root selector matches the document's root element.
  In HTML, the root element is always html. By convention,
  custom properties are always defined in :root (not in html).
*/
:root {
  --default-font-family: "Rubik", sans-serif;
  /*
      16px is the default font size set by browsers in the html tag.
      This should never be changed so that vision-impared users can override it.
      Therefore, 1rem (root em) is the width of an em-dash at 16px.
      If you want a differnt default size for your site, change it in the body tag.
      For example, if you want 20px, then set the size to 20/16 = 1.25rem.
    */
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

[class$="--Results"]{
  z-index: 10000 !important;
}
